import NotesAPI from '@/api/api'


export default {
  state: {
    categories: []
  },
  mutations: {
    setCategories: (state, payload) => {
      state.categories = payload
    },
    addCategory: (state, payload) => {
      state.categories.push(payload)
    },
    removeCategoryByIndex: (state, i) => {
      state.categories = state.categories.slice(0, i-1).concat(state.categories.slice(i, state.categories.length))
    },
    removeCategoryById: (state, id) => {
      state.categories = state.categories.filter(function(item) { return item.id !== id })
    }
  },
  getters: {
    getCategories (state) {
      return state.categories
    },
    getCategoriesCount (state) {
      return state.categories.length
    }
  },
  actions: {
    updateCategories (context) {
      return NotesAPI.thread_category_list(context.rootState.auth.token).then((response) => {
        context.commit('setCategories', response)
      })
    },
    createCategory (context, payload) {
      return NotesAPI.thread_category_create(context.rootState.auth.token, payload).then((response) => {
        context.dispatch('updateCategories')
        return response.data
      })
    },
    updateCategory (context, payload) {
      return NotesAPI.thread_category_update(context.rootState.auth.token, payload).then((response) => {
        context.dispatch('updateCategories')
        return response.data
      })
    },
    deleteCategory (context, payload) {
      return NotesAPI.thread_category_delete(context.rootState.auth.token, payload).then((response) => {
        context.dispatch('updateCategories')
        context.dispatch('updateUsers')
        return response.data
      })
    }
  }

}
