import NotesAPI from '@/api/api'


export default {
  state: {
    users: []
  },
  mutations: {
    setUsers: (state, payload) => {
      state.users = payload
    }
  },
  getters: {
    getUsers (state) {
      return state.users
    },
    getUsersCount (state) {
      return state.users.length
    },
    hasAdmin (state) {
      return state.users.filter((item) => item.role === "administrator").length > 0
    }
  },
  actions: {
    updateUsers (context) {
      return NotesAPI.users_list(context.rootState.auth.token).then((response) => {
        context.commit('setUsers', response)
      })
    },
    createUser (context, payload) {
      return NotesAPI.users_create(context.rootState.auth.token, payload).then((response) => {
        context.dispatch('updateUsers')
        return response.data
      })
    },
    updateUser (context, payload) {
      return NotesAPI.users_update(context.rootState.auth.token, payload).then((response) => {
        context.dispatch('updateUsers')
        return response.data
      })
    },
    deleteUser (context, payload) {
      return NotesAPI.users_delete(context.rootState.auth.token, payload).then((response) => {
        context.dispatch('updateUsers')
        return response.data
      })
    }
  }

}
