import moment from 'moment'
import StatsAPI from '@/api/api'

export default {
  state: {
    token: null,
    username: null,
    dateDisable: null,
    dateInactivity: null,
    maxUsers: null,
    isAuthenticated: false 
  },
  mutations: {
    loggedIn: (state, payload) => {
      state.username = payload.username
      state.token = payload.token
      state.dateDisable = moment(payload.date_disable)
      state.dateInactivity = moment(payload.date_inactivity)
      state.maxUsers = payload.max_users
      state.isAuthenticated = true
    }
  },
  getters: {
    getUsername (state) {
      return state.username
    },
    getMaxUsers (state) {
      return state.maxUsers
    },
    isDisabled (state) {
      if (state.dateDisable === null) {
        return false
      }
      return moment() > state.dateDisable
    },
    isInactive (state) {
      if (state.dateInactivity === null) {
        return false
      }
      return moment() > state.dateInactivity
    },
    isLoggedIn (state) {
      return state.isAuthenticated
    },
    getToken (state) {
      return state.token
    }
  },
  actions: {
    ensureLoggedIn (context, token) {
      console.log("ensuring logged in...")
      return new Promise((resolve) => {
        StatsAPI.me().then((response) => {
          console.log(response.status)
          if (response.status === 200) {
            if (response.data.vistazoo_token === token) {
              resolve(context.commit('loggedIn', response.data))
              return              
            }
          }
          resolve(context.dispatch('doLogin', token))
        }).catch(() => {
          console.log("Not logged in. Trying to login...")
          resolve(context.dispatch('doLogin', token))
        })
      })
    },
    doLogin (context, token) {
      context.commit('setToken', token)
      return StatsAPI.login(token).then((response) => {
        console.log("Logged in")
        console.log(response)
        context.dispatch('ensureLoggedIn', token)
        return context.commit('loggedIn', response.data)
      }).catch(() => {
        console.log("Authentication error")
      })
    }
  }

}
