<template>
  <div id="app">
    <main>
      <div class="wrapper">
        <div class="bar-navigation sps">
          <top-menu></top-menu>
        </div>
        <div class="jander">
          <div class="error-message text-danger bg-white p-2 text-right" v-if="!isLoggedIn">
            <span class="material-icons">do_not_touch</span> ANONYMOUS USER. NO DATA.
          </div>
          <transition name="fade">
            <router-view></router-view>
          </transition>          
        </div>
        <portal-target name="footer" slim></portal-target>
      </div>
    </main>
    <portal-target name="modals"><BModal></BModal></portal-target>
  </div>
</template>

<script>
  import TopMenu from '@/components/TopMenu.vue'
  import { BModal } from 'bootstrap-vue'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    components: {
      BModal,
      TopMenu
    },
    methods: {
      ...mapActions(['ensureLoggedIn', 'updateCategories', 'updateUsers']),
      checkLogin () {
        if ('token' in this.$route.query) {
          let token = this.$route.query.token
          this.token = token
          this.ensureLoggedIn(token).catch(() => {
            this.$router.push({'name': 'error', 'query': {'message': 'No credentials were provided.'}})
            this.loggedIn = false
          }).then(() => {
            this.loggedIn = true
            this.showFilters = false
            this.$nextTick(() => {
              this.showFilters = true
              this.updateCategories()
              this.updateUsers()
            })
          })
        } else {
          this.loggedIn = false
          setTimeout(() => { this.checkLogin() }, 1500)

        }
      }
    },
    computed: {
      ...mapGetters(['isLoggedIn'])
    },
    watch: {
      async $route () {
        if (!this.loggedIn) {
          // await this.checkLogin()
        }
      }
    },
    data () {
      return {
        loggedIn: false,
        showFilters: true,
        token: null
      }
    },
    async mounted () {
      await this.checkLogin()
    }
  };
</script>

<style lang="scss">

.jander {
  height: calc(100vh - 80px);
  overflow-y: scroll;
  overflow-x: hidden;
  background: white;
}

.jander::-webkit-scrollbar, *::-webkit-scrollbar {
  width: 8px;
}
 
.jander::-webkit-scrollbar-track, *::-webkit-scrollbar-track {
  background: transparent;
}
 
.jander::-webkit-scrollbar-thumb, *::-webkit-scrollbar-thumb {
  background: #008dca; 
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
  // transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.fade-enter {
  opacity: 0;
  overflow: hidden;
}

.fade-leave-active {
  opacity: 0;
  overflow: hidden;
}
.error-message {
  .material-icons {
    vertical-align: bottom;
  }
}
</style>
