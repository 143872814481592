import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import PortalVue from 'portal-vue'
import VCalendar from 'v-calendar'
import './validationRules'
import VueSweetalert2 from 'vue-sweetalert2'

// Styling
import '@/assets/scss/fonts.scss'
import '../node_modules/bootstrap/scss/bootstrap.scss'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/scss/main.scss'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'vue-swatches/dist/vue-swatches.css'
import 'sweetalert2/dist/sweetalert2.min.css'

import Vue2TouchEvents from 'vue2-touch-events'
 

Vue.use(PortalVue)
Vue.use(VCalendar)
Vue.use(Vue2TouchEvents)
Vue.use(VueSweetalert2)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
