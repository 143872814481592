<template>
  <ul class="nav nav-pills" v-if="false">
    <li class="nav-item">
      <router-link class="nav-link" to="/">Configuration</router-link>
    </li>
  </ul>
</template>

<script>
  export default {
  };
</script>

<style>
.nav.nav-pills {
  margin-right: 23px;
}

@media screen and (max-width: 767px) {
  .nav.nav-pills .nav-item .nav-link span {
    display: none;
  }
  .nav.nav-pills .nav-item .nav-link strong {
    font-size: 0.9em
  }
}
</style>
