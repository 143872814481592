import axios from 'axios'

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
axios.defaults.xsrfCookieName = "csrftoken"
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASEURL

export const NotesAPI = {
  axios,
  getCookie (name) {
    let match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
  },
  getConfig (data, token) {
    var config = { 'headers': {} }
    if (data) {
      config['headers']['Content-Type'] = 'multipart/form-data'
    }
    if (token) {
      config['headers']['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  getPayload (data) {
    var payload = new FormData()
    for (var key in data) {
      if (Array.isArray(data[key])) {
        data[key].forEach(item => {
          payload.append(key, item)
        })
      } else {
        payload.set(key, data[key])
      }
    }
    payload.set('csrfmiddlewaretoken', this.getCookie('csrftoken'))
    return payload
  },
  me () {
    return axios.get('/vistazoo_users/me/')
  },
  login (token) {
    let payload = this.getPayload({token})
    let config = this.getConfig(payload, null)
    return axios.post('/login/', payload, config)
  },
  async thread_category_list (token) {
    let config = this.getConfig(null, token)
    let results = []
    let response = await axios.get('/thread_category/', config)
    results.push(...response.data.results)
    while (response.data.next) {
      response = await axios.get(response.data.next, config)
      results.push(...response.data.results)
    }
    return results
  },
  thread_category_create (token, data) {
    let payload = this.getPayload(data)
    let config = this.getConfig(payload, token)
    return axios.post('/thread_category/', payload, config)
  },
  thread_category_delete (token, data) {
    let payload = this.getPayload(data)
    let config = this.getConfig(payload, token)
    config['data'] = payload
    return axios.delete('/thread_category/' + data.id + '/', config)
  },
  thread_category_update (token, data) {
    let payload = this.getPayload(data)
    let config = this.getConfig(payload, token)
    return axios.put('/thread_category/' + data.id + '/', payload, config)
  },
  async users_list (token) {
    let config = this.getConfig(null, token)
    let results = []
    let response = await axios.get('/users/', config)
    results.push(...response.data.results)
    while (response.data.next) {
      response = await axios.get(response.data.next, config)
      results.push(...response.data.results)
    }
    return results
  },
  users_create (token, data) {
    let payload = this.getPayload(data)
    let config = this.getConfig(payload, token)
    return axios.post('/users/', payload, config)
  },
  users_delete (token, data) {
    let payload = this.getPayload(data)
    let config = this.getConfig(payload, token)
    config['data'] = payload
    return axios.delete('/users/' + data.id + '/', config)
  },
  users_update (token, data) {
    let payload = this.getPayload(data)
    let config = this.getConfig(payload, token)
    return axios.put('/users/' + data.id + '/', payload, config)
  },
  users_set_password (token, id, password) {
    let payload = this.getPayload({'password': password})
    let config = this.getConfig(payload, token)
    return axios.put('/users/' + id + '/set_password/', payload, config)
  },
  users_reset_password (token, id) {
    let payload = this.getPayload({})
    let config = this.getConfig(payload, token)
    return axios.post('/users/' + id + '/reset_password/', payload, config)
  }

}

export default NotesAPI
